import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Heading from './../../components/Heading';

import FileBase64 from 'react-file-base64';
import { createHeader, updateHeader } from '../../actions/header';

import loadingImg from '../../assets/img/loading.gif';


function HeaderForm({ currentId, setCurrentId, refreshState, setRefreshState, toggleContent }) {
    const dispatch = useDispatch();

    const [ headerData, setHeaderData ] = useState({
        chLink: '',
        apLink: '',
        bgImg: '',
      
    })

    const [ loading, setLoading ] = useState(null)

    const header = useSelector((state) => currentId ? state.header.response.find((p) => p.id === currentId) : null);

    useEffect(() => {
        if(header){ setHeaderData(header);}
    }, [header])

    const handleSubmit = (e) => {
        e.preventDefault(); //not to get refresh in the browser

        setLoading(<div  className="loadingStatus" ><img src={loadingImg} alt=""/> <h5> Uploading!</h5></div>)

        if(currentId){
                    dispatch(updateHeader(currentId, headerData)).then(() => toggleContent(false));
                    setRefreshState(!refreshState);
    
    
                }else{
                    dispatch(createHeader(headerData)).then(() => toggleContent(false));
                    setRefreshState(!refreshState);
    
                }
                clear();
    }

    const clear = () => {
        setCurrentId(null);

        setHeaderData({
            chLink: '',
            apLink: '',
            bgImg: '',
        })
    }

    return (
        <>
            <section className= "postProduct">
                <div className= "postProduct__wrapper">
                    <Heading main= "Admin" regular= "Panel" addClass= "margin-bottom-small" />

                    <form autoComplete= "off" className= "contact__modal-form" onSubmit= {handleSubmit}>
                        <input type="text" id="chLink" title="chLink" placeholder="Channel Link" value= {headerData.chLink} onChange= {(e) => setHeaderData({...headerData, chLink: e.target.value })} required/>
                        <input type="text" id="apLink" title="apLink" placeholder="Apply Link" value= {headerData.apLink} onChange= {(e) => setHeaderData({...headerData, apLink: e.target.value })} required/>
                       
                        <div className= "margin-bottom-small">
                            Background Image: <FileBase64 type="file" multiple={false} onDone={({base64}) => {setHeaderData({ ...headerData, bgImg: base64 }) }} />
                        </div>
        
                        <div className="btn__adminWrapper">
                            <button className="btn__admin margin-bottom-small" type= "submit">Submit</button>
                            {loading}
                        </div>
                    </form>
                </div>
            </section>  
        </>
    )
}

export default HeaderForm
