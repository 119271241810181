import * as api from '../api';

//Action Creators

export const getMerch = () => async (dispatch) => {
    try {
        const { data } = await api.fetchMerch();

        dispatch({ type: 'FETCH_MERCH', payload: data });

    } catch( error ) {
        console.log(error.message);
    }
}

export const createMerch = (apply) => async (dispatch) => {
    try{
        const { data } = await api.createMerch(
            {
                buLink: apply.buLink,
                bgImg: apply.bgImg,
            }
        );

        dispatch({ type: 'CREATE_MERCH', payload: data });
        return(console.log("posted!!"))
    }catch (error) {
        console.log(error);
    }
}

export const updateMerch = (id, apply) => async(dispatch) => {
    try{
        const { data } = await api.updateMerch(id,{
            buLink: apply.buLink,
            bgImg: apply.bgImg,

        });
        dispatch({ type: 'UPDATE_MERCH', payload: data });
    }catch(error) {
        console.log(error);
    }
}

export const deleteMerch = (id) => async(dispatch) => {
    try{
        await api.deleteMerch(id);

        dispatch({ type: 'DELETE_MERCH', payload: id})
    } catch(error) {
        console.log(error)
    }
}
