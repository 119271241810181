import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Heading from './../../components/Heading';

import FileBase64 from 'react-file-base64';
import { createMerch, updateMerch } from '../../actions/merch';

import loadingImg from '../../assets/img/loading.gif';


function MerchForm({ currentId, setCurrentId, refreshState, setRefreshState, toggleContent }) {
    const dispatch = useDispatch();

    const [ merchData, setMerchData ] = useState({
        buLink: '',
        bgImg: '',
      
    })

    const [ loading, setLoading ] = useState(null)

    const merch = useSelector((state) => currentId ? state.merch.response.find((p) => p.id === currentId) : null);

    useEffect(() => {
        if(merch){ setMerchData(merch);}
    }, [merch])

    const handleSubmit = (e) => {
        e.preventDefault(); //not to get refresh in the browser

        setLoading(<div  className="loadingStatus" ><img src={loadingImg} alt=""/> <h5> Uploading!</h5></div>)

        if(currentId){
                    dispatch(updateMerch(currentId, merchData)).then(() => toggleContent(false));
                    setRefreshState(!refreshState);
    
    
                }else{
                    dispatch(createMerch(merchData)).then(() => toggleContent(false));
                    setRefreshState(!refreshState);
    
                }
                clear();
    }

    const clear = () => {
        setCurrentId(null);

        setMerchData({
            buLink: '',
            bgImg: '',
        })
    }

    return (
        <>
            <section className= "postProduct">
                <div className= "postProduct__wrapper">
                    <Heading main= "Admin" regular= "Panel" addClass= "margin-bottom-small" />

                    <form autoComplete= "off" className= "contact__modal-form" onSubmit= {handleSubmit}>
                        <input type="text" id="buLink" title="buLink" placeholder="Buy Now Link" value= {merchData.buLink} onChange= {(e) => setMerchData({...merchData, buLink: e.target.value })} required/>
                       
                        <div className= "margin-bottom-small">
                            Background Image: <FileBase64 type="file" multiple={false} onDone={({base64}) => {setMerchData({ ...merchData, bgImg: base64 }) }} />
                        </div>
        
                        <div className="btn__adminWrapper">
                            <button className="btn__admin margin-bottom-small" type= "submit">Submit</button>
                            {loading}
                        </div>
                    </form>
                </div>
            </section>  
        </>
    )
}

export default MerchForm
