export default (work= [], action) => {
    switch (action.type) {
        case 'UPDATE_WORK':
            return work.filter((work) => work.id !== action.payload);
        case 'UPDATE_WORK':
            return work.map((work) => work.id === action.payload.id ? action.payload: work)
        case 'CREATE_WORK':
            return [...work, action.payload];
        case 'FETCH_WORK':
            return work = action.payload;
        default:
            return work
    }
}