import * as api from '../api';

//Action Creators

export const getApply = () => async (dispatch) => {
    try {
        const { data } = await api.fetchApply();

        dispatch({ type: 'FETCH_APPLY', payload: data });

    } catch( error ) {
        console.log(error.message);
    }
}

export const createApply = (apply) => async (dispatch) => {
    try{
        const { data } = await api.createApply(
            {
                subLink: apply.subLink,
                bgImg: apply.bgImg,
            }
        );

        dispatch({ type: 'CREATE_APPLY', payload: data });
        return(console.log("posted!!"))
    }catch (error) {
        console.log(error);
    }
}

export const updateApply = (id, apply) => async(dispatch) => {
    try{
        const { data } = await api.updateApply(id,{
            subLink: apply.subLink,
            bgImg: apply.bgImg,

        });
        dispatch({ type: 'UPDATE_APPLY', payload: data });
    }catch(error) {
        console.log(error);
    }
}

export const deleteApply = (id) => async(dispatch) => {
    try{
        await api.deleteApply(id);

        dispatch({ type: 'DELETE_APPLY', payload: id})
    } catch(error) {
        console.log(error)
    }
}
