import React, {useEffect} from 'react'

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { getHeader } from './../actions/header';
import { imgDirectory } from './ImgDirectory';

function Header() {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getHeader());

    },[])


    const header= useSelector((state) => state.header.response);

    let chLink,apLink,bgImg;
    if(Array.isArray(header)){
        header.map((data,index) => {
            chLink = data.chLink;
            apLink = data.apLink;
            bgImg = data.bgImg;
        })
    }

    return (
        <>
            <header className="header">
                <img src={imgDirectory + bgImg} alt="" className="header__bgImg" />
                <div className="header__container header__container-resp">
                    <h1 className="heading-large--white header__heading">
                        <span className="header__heading-main header__heading-main--anim"><span className="header__dot">. </span>miyani </span>
                        <span className="header__heading-sub header__heading-sub--anim">sessions</span>
                    </h1>
                    <div className="header__btnGroup header__btnGroup-resp">
                        <a href={chLink} target="blank" className="cta">Go To Channel</a>
                        <a href={apLink} target="blank" className="cta">Apply</a>
                    </div>
                    <p className="header__mark heading-tiny">SCROLL DOWN</p>
                </div>
            </header>
        </>
    )
}

export default Header
