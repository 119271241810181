import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getApply } from './actions/apply';
import { getBrand } from './actions/brand';
import { getCategory } from './actions/category';
import { getHeader } from './actions/header';
import { getMerch } from './actions/merch';
import { getPlaylist } from './actions/playlist';
import { getWork } from './actions/work';

import Home from "./pages/Home"
import "./styles/App.scss"
import AnimatedCursor from "react-animated-cursor"
import AdminHome from './admin/pages/AdminHome';
import PostCategory from './admin/pages/PostCategory';
import PostApply from './admin/pages/PostApply';
import PostHeader from './admin/pages/PostHeader';
import PostMerch from './admin/pages/PostMerch';
import PostPlaylist from './admin/pages/PostPlaylist';
import PostWork from './admin/pages/PostWork';


function App() {

  let pathname = window.location.pathname
  let pathArray = window.location.pathname.split('/');
  var secondLevelLocation = pathArray[2];

  let cur;
  if(pathname.toLowerCase() ==="/"){
      cur = <div className="cursor">
      <AnimatedCursor outerSize= {18} innerSize = {20} outerScale = {3} color='255, 255, 255'/>
    </div>
  }else {
    cur = ""
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getApply());
    dispatch(getBrand());
    dispatch(getCategory());
    dispatch(getHeader());
    dispatch(getMerch());
    dispatch(getPlaylist());
    dispatch(getWork());
  }, [dispatch]);


  return (
    <>
      {cur}
      <div className="container">
        <Router>
          <Switch>
            <Route path='/admin' exact component = {AdminHome}/>
            <Route path='/admin/add-apply' exact component = {PostApply}/>
            {/* <Route path='/admin/add-brand' exact component = {PostProduct}/> */}
            <Route path='/admin/add-category' exact component = {PostCategory}/>
            <Route path='/admin/add-header' exact component = {PostHeader}/>
            <Route path='/admin/add-merch' exact component = {PostMerch}/>
            <Route path='/admin/add-playlist' exact component = {PostPlaylist}/>
            <Route path='/admin/add-work' exact component = {PostWork}/>

            <Route path='/' exact component = {Home}/>

          </Switch>
        </Router>
        {/* <Home/> */}
        
        <div className="home__grid1"></div>
        <div className="home__grid2"></div>
        <div className="home__grid3"></div>
        <div className="home__grid4"></div>
    </div>
    </>
  );
}

export default App;
