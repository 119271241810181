import React, {useEffect} from 'react'

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { getWork } from './../actions/work';
import { imgDirectory } from './ImgDirectory';

function Work() {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getWork());

    },[])


    const work= useSelector((state) => state.work.response);

    let stLink,bgImg;
    if(Array.isArray(work)){
        work.map((data,index) => {
            stLink = data.stLink;
            bgImg = data.bgImg;
        })
    }

    return (
        <>
            <section className="work">
                <img src={imgDirectory + bgImg} alt="" className="work__bgImg" />
                <div className="work__container">
                    <h1 className="heading-large--white work__heading">
                        <span className="header__heading-main">Work with</span>
                        <span className="header__heading-sub">the team</span>
                    </h1>
                    <div className="header__btnGroup">
                        <a href={stLink} className="cta">Get Started</a>
                    </div>
                    <p className="header__mark heading-tiny">MIYANI SESSIONS</p>
                </div>
            </section>
        </>
    )
}

export default Work
