import React, {useEffect} from 'react'

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { getApply } from './../actions/apply';
import { imgDirectory } from './ImgDirectory';

function Apply() {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getApply());

    },[])


    const apply= useSelector((state) => state.apply.response);

    let subLink,bgImg;
    if(Array.isArray(apply)){
        apply.map((data,index) => {
            subLink = data.subLink;
            bgImg = data.bgImg;
        })
    }

    return (
        <>
            <section className="apply">
                <img src={imgDirectory + bgImg} alt="" className="apply__bgImg" />
                <div className="apply__container">
                    <h1 className="heading-large--white apply__heading">
                        <span className="header__heading-main">Apply</span>
                        <span className="header__heading-sub">Now</span>
                    </h1>
                    <div className="header__btnGroup apply__btn">
                        <a href={subLink} target="blank" className="cta">Submit Your Music</a>
                    </div>
                    <p className="header__mark heading-tiny">MIYANI SESSIONS</p>
                </div>
                    {/* <div className="hr-1"></div>
                    <div className="hr-2"></div>
                    <div className="hr-3"></div>
                    <div className="hr-4"></div>
                    <div className="hr-5"></div> */}
            </section>
        </>
    )
}

export default Apply
