import React, {useEffect} from 'react'

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { getMerch } from './../actions/merch';
import { imgDirectory } from './ImgDirectory';

function Merch() {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getMerch());

    },[])


    const merch= useSelector((state) => state.merch.response);

    let buLink,bgImg;
    if(Array.isArray(merch)){
        merch.map((data,index) => {
            buLink = data.buLink;
            bgImg = data.bgImg;
        })
    }

    return (
        <>
            <section className="merch">
                <img src={imgDirectory + bgImg} alt="" className="merch__bgImg" />
                <div className="merch__container">
                    <h1 className="heading-large--black merch__heading">
                        <span className="header__heading-main">Our</span>
                        <span className="header__heading-sub">Merch</span>
                    </h1>
                    <div className="header__btnGroup">
                        <a href={buLink} className="cta cta-bl">Buy Now</a>
                    </div>
                    <p className="header__mark heading-tiny">MIYANI SESSIONS</p>
                </div>
            </section>   
        </>
    )
}

export default Merch
