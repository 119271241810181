import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getMerch, deleteMerch } from '../../actions/merch';

import Sidebar from './../components/Sidebar';


import MerchForm from './../components/MerchForm';
import MerchCard from './../components/MerchCard';
import Heading from './../../components/Heading';

function PostMerch() {

    const [currentId, setCurrentId] = useState(null);
    const [refreshState, setRefreshState] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMerch());
    }, [refreshState, dispatch])

    const merch = useSelector((state) => state.merch);

    let dispMerch = "";

    if(merch.response){
        dispMerch = merch.response.map((dispMerchData) => (
        <MerchCard data= {dispMerchData} setCurrentId= {setCurrentId} deleteHandler= {(merchId) => deleteFunc(merchId)}  refreshState= {refreshState} setRefreshState= {setRefreshState} toggleContent = {(condition) => toggleContent(condition)}/>
    ))
    }

     //TOBEDISPLAYEDCONTENT
     const [addMerch, setAddMerch] = useState(false);

     let content = "";
     const toggleContent = (condition) => {
         setAddMerch(condition);
     }
 
     if(addMerch) {
         content = 
             <>
                 <button onClick = {() => toggleContent(false)} className= "btn__admin" >All Merch</button>
                 <MerchForm currentId = {currentId} setCurrentId= {setCurrentId} refreshState= {refreshState} setRefreshState= {setRefreshState} toggleContent = {(condition) => toggleContent(condition)}/>
             </>
     }else {
         content =
             <>
                 <button onClick = {() => {toggleContent(true); setCurrentId(null)}} className= "btn__admin" >Add Merch</button>
                 <section className="fetchedProducts">
                     {dispMerch}
                 </section>
             </>
     }

     //DELETE NEWS
     const deleteFunc = (dispMerchId) => {
        dispatch(deleteMerch(dispMerchId))
    }

    return (
        <>
             <section className="adminHome">
                <div className="adminHome__container">
                    <div className="adminHome__navigation">
                        <Heading main= "Admin" sub= "Panel" addClass= "margin-bottom-small" />


                        <Sidebar/>
                    </div>
                    <div className="adminHome__content">
                        {content}
                    </div>

                </div>
            </section>   
        </>
    )
}

export default PostMerch
