import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Heading from './../../components/Heading';

import FileBase64 from 'react-file-base64';
import { createCategory, updateCategory } from '../../actions/category';

import loadingImg from '../../assets/img/loading.gif';


function CategoryForm({ currentId, setCurrentId, refreshState, setRefreshState, toggleContent }) {
    const dispatch = useDispatch();

    const [ categoryData, setCategoryData ] = useState({
        orgLink: '',
        covLink: '',
        orgBgImg: '',
        covBgImg: '',
      
    })

    const [ loading, setLoading ] = useState(null)

    const category = useSelector((state) => currentId ? state.category.response.find((p) => p.id === currentId) : null);

    useEffect(() => {
        if(category){ setCategoryData(category);}
    }, [category])

    const handleSubmit = (e) => {
        e.preventDefault(); //not to get refresh in the browser

        setLoading(<div  className="loadingStatus" ><img src={loadingImg} alt=""/> <h5> Uploading!</h5></div>)

        if(currentId){
                    dispatch(updateCategory(currentId, categoryData)).then(() => toggleContent(false));
                    setRefreshState(!refreshState);
    
    
                }else{
                    dispatch(createCategory(categoryData)).then(() => toggleContent(false));
                    setRefreshState(!refreshState);
    
                }
                clear();
    }

    const clear = () => {
        setCurrentId(null);

        setCategoryData({
            orgLink: '',
            covLink: '',
            orgBgImg: '',
            covBgImg: '',
        })
    }

    return (
        <>
            <section className= "postProduct">
                <div className= "postProduct__wrapper">
                    <Heading main= "Admin" regular= "Panel" addClass= "margin-bottom-small" />


                    <form autoComplete= "off" className= "contact__modal-form" onSubmit= {handleSubmit}>
                        <input type="text" id="orgLink" title="orgLink" placeholder="Originals Link" value= {categoryData.orgLink} onChange= {(e) => setCategoryData({...categoryData, orgLink: e.target.value })} required/>
                        <input type="text" id="covLink" title="covLink" placeholder="Covers Link" value= {categoryData.covLink} onChange= {(e) => setCategoryData({...categoryData, covLink: e.target.value })} required/>
                       
                        <div className= "margin-bottom-small">
                            Originals Background Image: <FileBase64 type="file" multiple={false} onDone={({base64}) => {setCategoryData({ ...categoryData, orgBgImg: base64 }) }} />
                        </div>
                        <div className= "margin-bottom-small">
                            Covers Background Image: <FileBase64 type="file" multiple={false} onDone={({base64}) => {setCategoryData({ ...categoryData, covBgImg: base64 }) }} />
                        </div>
        
                        <div className="btn__adminWrapper">
                            <button className="btn__admin margin-bottom-small" type= "submit">Submit</button>
                            {loading}
                        </div>
                    </form>
                </div>
            </section>  
        </>
    )
}

export default CategoryForm
