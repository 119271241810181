export default (merch= [], action) => {
    switch (action.type) {
        case 'UPDATE_MERCH':
            return merch.filter((merch) => merch.id !== action.payload);
        case 'UPDATE_MERCH':
            return merch.map((merch) => merch.id === action.payload.id ? action.payload: merch)
        case 'CREATE_MERCH':
            return [...merch, action.payload];
        case 'FETCH_MERCH':
            return merch = action.payload;
        default:
            return merch
    }
}