import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Heading from './../../components/Heading';

import FileBase64 from 'react-file-base64';
import { createPlaylist, updatePlaylist } from '../../actions/playlist';

import loadingImg from '../../assets/img/loading.gif';


function PlaylistForm({ currentId, setCurrentId, refreshState, setRefreshState, toggleContent }) {
    const dispatch = useDispatch();

    const [ playlistData, setPlaylistData ] = useState({
        spLink: '',
        ytLink: '',
        bgImg: '',
      
    })

    const [ loading, setLoading ] = useState(null)

    const playlist = useSelector((state) => currentId ? state.playlist.response.find((p) => p.id === currentId) : null);

    useEffect(() => {
        if(playlist){ setPlaylistData(playlist);}
    }, [playlist])

    const handleSubmit = (e) => {
        e.preventDefault(); //not to get refresh in the browser

        setLoading(<div  className="loadingStatus" ><img src={loadingImg} alt=""/> <h5> Uploading!</h5></div>)

        if(currentId){
                    dispatch(updatePlaylist(currentId, playlistData)).then(() => toggleContent(false));
                    setRefreshState(!refreshState);
    
    
                }else{
                    dispatch(createPlaylist(playlistData)).then(() => toggleContent(false));
                    setRefreshState(!refreshState);
    
                }
                clear();
    }

    const clear = () => {
        setCurrentId(null);

        setPlaylistData({
            spLink: '',
            ytLink: '',
            bgImg: '',
        })
    }

    return (
        <>
            <section className= "postProduct">
                <div className= "postProduct__wrapper">
                    <Heading main= "Admin" regular= "Panel" addClass= "margin-bottom-small" />

                    <form autoComplete= "off" className= "contact__modal-form" onSubmit= {handleSubmit}>
                        <input type="text" id="spLink" title="spLink" placeholder="Spotify Link" value= {playlistData.spLink} onChange= {(e) => setPlaylistData({...playlistData, spLink: e.target.value })} required/>
                        <input type="text" id="ytLink" title="ytLink" placeholder="Youtube Link" value= {playlistData.ytLink} onChange= {(e) => setPlaylistData({...playlistData, ytLink: e.target.value })} required/>
                       
                        <div className= "margin-bottom-small">
                            Background Image: <FileBase64 type="file" multiple={false} onDone={({base64}) => {setPlaylistData({ ...playlistData, bgImg: base64 }) }} />
                        </div>
        
                        <div className="btn__adminWrapper">
                            <button className="btn__admin margin-bottom-small" type= "submit">Submit</button>
                            {loading}
                        </div>
                    </form>
                </div>
            </section>  
        </>
    )
}

export default PlaylistForm
