import React, {useEffect} from 'react'
import animation from '../assets/img/intro.gif';

import gsap from 'gsap';
import logo from '../assets/img/logos/logo.png'

function IntroAnimation() {
    const tl = gsap.timeline();
    
    

    // useEffect(() => {
        
    //     tl.set(".intro__logo", {
    //         xPercent: -135,
    //         yPercent: -220,
    //         scale: 2.9,
    //         x: window.innerWidth / 2,
    //         y: window.innerHeight / 2,
    //         opacity: 0
    //       }).set(".sidebar__logo", {
    //         opacity: 0
    //       }) .to(".intro__animation", {
    //         opacity: 0,
    //         duration: .1
    //       }, "+=2.7")
    //       .to(".intro__logo", {
    //         opacity: 1,
    //         duration: 0,
    //         delay: -.2
    //       })
    //       // .to(".intro__overlay", .2, {
    //       //   borderRightWidth: .5 ,
    //       //   borderRightColor: '#E8E3D9',
    //       //   borderRightStyle: 'solid',
    //       //   ease: "expo.inOut",
    //       //   stagger: 0.1
    //       //   })
    //       .to(".overlay-bottom", .6, {
    //         height: 0,
    //         ease: "expo.inOut",
    //         stagger: 0.2
    //         })
    //       .to(".overlay-top", .6, {
    //         height: 0,
    //         ease: "expo.inOut",
    //         stagger: 0.2,
    //         delay: -.6
    //         })
    //       .to(".intro__logo", {
    //         xPercent: 0,
    //         yPercent: 0,
    //         x: 0,
    //         y: 0,
    //         scale: 1,
    //         display: 'none',
    //         ease: "power1.out",
    //         duration: 1.2,
    //         delay: -.2
    //       })
    //       .to(".sidebar__logo", {
    //         opacity: 1,
    //         delay: -.2,
    //         duration: 0
    //       })
    //       .to(".intro", {
    //         display: 'none',
    //         duration: 0
    //       }, "+=0")
    // },[])

    useEffect(() => {
        
      
      tl.set(".intro__logo", {
        xPercent: -135,
        yPercent: -220,
        scale: 2.9,
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
        opacity: 1
      })
      .to(".intro__animation-bg", 0, {
        opacity: 0,
        display: 'none',
        ease: "expo.inOut",
        stagger: 0.2,
        delay: 2.7
        })
      .from(".intro__overlay", 1.2, {
        height: 0,
        ease: "expo.inOut",
        stagger: 0.2
        })
      .set(".intro__overlay", {
        top: 0
        })
      .set(".intro__logoBox", {
        opacity: 0,
        display: 'none',
        })
      .to(".intro__overlay", 1.2, {
        height: 0,
        ease: "expo.inOut",
        stagger: 0.2,
        // delay: -.1
        })
      .to(".intro", {
          display: 'none',
          duration: 0
        }, "+=0")
  },[])

   
    return (
        <>
            <section className="intro">
                  <div className="intro__animation-bg">
                    <img src={animation} alt="" className="intro__animation" />
                  </div>
                <div className="intro__logoBox">
                   <img src={logo} alt="" className="intro__logo" />
                </div>
                <div className="intro__wrapper">
                    {/* <div className="intro__overlay overlay-bottom"></div>
                    <div className="intro__overlay overlay-top"></div>
                    <div className="intro__overlay overlay-bottom"></div>
                    <div className="intro__overlay overlay-top"></div>
                    <div className="intro__overlay overlay-bottom"></div>
                    <div className="intro__overlay overlay-top"></div> */}
                    <div className="intro__overlay overlay"></div>
                    <div className="intro__overlay overlay"></div>
                    <div className="intro__overlay overlay"></div>
                    <div className="intro__overlay overlay"></div>
                    <div className="intro__overlay overlay"></div>
                    <div className="intro__overlay overlay"></div>
                </div>
            </section>
        </>
    )
}

// FDFDE4
export default IntroAnimation
