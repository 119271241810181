import axios from 'axios';

// const applyUrl = 'http://localhost:3001/api/apply';
// const brandUrl = 'http://localhost:3001/api/brand';
// const categoryUrl = 'http://localhost:3001/api/category';
// const headerUrl = 'http://localhost:3001/api/header';
// const merchUrl = 'http://localhost:3001/api/merch';
// const playlistUrl = 'http://localhost:3001/api/playlist';
// const workUrl = 'http://localhost:3001/api/work';

const applyUrl = 'http://miyaniserver.pixelcreatives.co/api/apply';
const brandUrl = 'http://miyaniserver.pixelcreatives.co/api/brand';
const categoryUrl = 'http://miyaniserver.pixelcreatives.co/api/category';
const headerUrl = 'http://miyaniserver.pixelcreatives.co/api/header';
const merchUrl = 'http://miyaniserver.pixelcreatives.co/api/merch';
const playlistUrl = 'http://miyaniserver.pixelcreatives.co/api/playlist';
const workUrl = 'http://miyaniserver.pixelcreatives.co/api/work';

export const fetchApply = () => axios.get(applyUrl);
export const createApply = (newApply) => axios.post(applyUrl, newApply);
export const updateApply = (id, updatedApply) => axios.patch(`${applyUrl}/${id}`, updatedApply)
export const deleteApply = (id) => axios.delete(`${applyUrl}/${id}`);

export const fetchBrand = () => axios.get(brandUrl);
export const createBrand = (newBrand) => axios.post(brandUrl, newBrand);
export const updateBrand = (id, updatedBrand) => axios.patch(`${brandUrl}/${id}`, updatedBrand)
export const deleteBrand = (id) => axios.delete(`${brandUrl}/${id}`);

export const fetchCategory = () => axios.get(categoryUrl);
export const createCategory = (newCategory) => axios.post(categoryUrl, newCategory);
export const updateCategory = (id, updatedCategory) => axios.patch(`${categoryUrl}/${id}`, updatedCategory)
export const deleteCategory = (id) => axios.delete(`${categoryUrl}/${id}`);

export const fetchHeader = () => axios.get(headerUrl);
export const createHeader = (newHeader) => axios.post(headerUrl, newHeader);
export const updateHeader = (id, updatedHeader) => axios.patch(`${headerUrl}/${id}`, updatedHeader)
export const deleteHeader = (id) => axios.delete(`${headerUrl}/${id}`);

export const fetchMerch = () => axios.get(merchUrl);
export const createMerch = (newMerch) => axios.post(merchUrl, newMerch);
export const updateMerch = (id, updatedMerch) => axios.patch(`${merchUrl}/${id}`, updatedMerch)
export const deleteMerch = (id) => axios.delete(`${merchUrl}/${id}`);

export const fetchPlaylist = () => axios.get(playlistUrl);
export const createPlaylist = (newPlaylist) => axios.post(playlistUrl, newPlaylist);
export const updatePlaylist = (id, updatedPlaylist) => axios.patch(`${playlistUrl}/${id}`, updatedPlaylist)
export const deletePlaylist = (id) => axios.delete(`${playlistUrl}/${id}`);

export const fetchWork = () => axios.get(workUrl);
export const createWork = (newWork) => axios.post(workUrl, newWork);
export const updateWork = (id, updatedWork) => axios.patch(`${workUrl}/${id}`, updatedWork)
export const deleteWork = (id) => axios.delete(`${workUrl}/${id}`);