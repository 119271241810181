import React from 'react'
import { Link } from 'react-router-dom';
import { imgDirectory } from '../../components/ImgDirectory';

function WorkCard(props) {

    let imageSrc = "";

    // if(props.data.bgImg !== undefined || props.data.bgImg !== null) {
    //     imageSrc = `http://localhost:3001/api/files/` + props.data.bgImg;
    // }

    if(props.data.bgImg !== undefined || props.data.bgImg !== null) {
        imageSrc = imgDirectory + props.data.bgImg;
    }
    const combinedFunc = () => {
        props.deleteHandler(props.data.id)

        props.setRefreshState(!props.refreshState);
    }


    return (
        <>
             <section className="photoCard">
                <div className="photoCard__container">
                    {/* <div className="photoCard__use">{props.data.date}</div> */}
                    <img src={imageSrc} alt="" className="photoCard__image"/>
                    <div className="photoCard__btnGroup">
                    <Link exact to="#" className="edit__btn" onClick= {() =>{props.setCurrentId(props.data.id); props.toggleContent(true)} }>
                        EDIT
                    </Link>

                    <Link exact to="#" className="delete__btn" onClick= {combinedFunc}>
                        DELETE
                    </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WorkCard
