import React from 'react'

function Sidebar(props) {
    return (
        <>
                <div className="sidebar__border"></div>
            <section className="sidebar">
            <a  onClick = {() => props.navFunc(0)} className="sidebar__btn sidebar__btn-anime">
                <svg  version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="164.000000pt" height="176.000000pt" viewBox="0 0 164.000000 176.000000"
                preserveAspectRatio="xMidYMid meet" className="sidebar__icon sidebar__logo">
                <g transform="translate(0.000000,176.000000) scale(0.100000,-0.100000)"
                stroke="none">
                <path d="M1160 1745 c-54 -18 -129 -71 -163 -117 -14 -18 -198 -319 -409 -668
                -211 -349 -409 -667 -439 -706 -69 -89 -149 -217 -149 -238 0 -11 9 -16 26
                -16 46 0 275 103 459 208 99 56 386 243 440 286 33 27 59 36 155 55 214 41
                308 108 393 279 42 85 42 86 42 197 l0 112 -43 85 c-38 75 -42 88 -32 114 21
                55 65 87 150 109 57 14 62 36 23 106 -37 68 -115 143 -182 176 -67 33 -199 42
                -271 18z m203 -61 c25 -9 66 -33 89 -54 l43 -38 -65 5 c-57 4 -69 2 -100 -19
                -49 -34 -80 -83 -80 -130 0 -52 29 -107 97 -180 78 -85 98 -133 98 -238 0 -77
                -3 -92 -36 -160 -63 -131 -136 -195 -268 -234 -81 -24 -87 -21 -48 27 89 110
                130 222 130 357 0 75 -4 107 -22 152 -38 97 -121 172 -239 215 -22 8 -21 10
                41 113 66 109 125 167 191 188 47 15 117 14 169 -4z m115 -167 l33 -12 -52
                -35 c-32 -21 -61 -50 -72 -72 -22 -43 -28 -42 -52 8 -14 30 -15 40 -4 65 23
                56 74 72 147 46z m-498 -218 c25 -11 62 -37 83 -57 61 -58 80 -104 85 -202 4
                -72 0 -97 -21 -157 -28 -81 -96 -189 -156 -247 -39 -38 -41 -39 -143 -47 -176
                -15 -318 -48 -407 -93 l-32 -17 13 24 c7 13 121 203 253 421 189 313 244 396
                260 396 11 0 40 -10 65 -21z m-160 -784 c0 -8 -197 -137 -317 -208 -62 -37
                -168 -94 -235 -128 -137 -69 -137 -69 -36 58 93 118 210 200 338 237 44 13
                163 35 243 45 4 1 7 -1 7 -4z"/>
                </g>
                </svg>
            </a>

            <a className="sidebar__navigation sidebar__btn" onClick = {() => props.navFunc(7)}>
                <span className="sidebar__navigation-icon-t">&nbsp;</span>
                
                <span className="sidebar__navigation-icon">&nbsp;</span>

                <span className="sidebar__navigation-icon-b">&nbsp;</span>
            </a>


            <div className="sidebar__social">
                <a href="#" className="sidebar__social-link sidebar__btn">
                    <svg className="sidebar__icon sidebar__icon-fb" xmlns="http://www.w3.org/2000/svg" width="9.073" height="17.404" viewBox="0 0 9.073 17.404">
                    <path id="Path_35" data-name="Path 35" d="M35.635,123.929c.54-.012,1.08,0,1.62,0h.228v-2.808c-.288-.024-.588-.072-.888-.084-.552-.024-1.1-.048-1.656-.036a4.081,4.081,0,0,0-2.34.72,3.508,3.508,0,0,0-1.4,2.34,8.088,8.088,0,0,0-.1,1.2c-.012.624,0,1.26,0,1.884v.24H28.41v3.132h2.664V138.4h3.264v-7.872H37c.132-1.044.264-2.076.408-3.144h-3.1s.012-1.548.024-2.232C34.338,124.241,34.891,123.953,35.635,123.929Z" transform="translate(-28.41 -120.997)" />
                    </svg>
                </a>
                <a href="#" className="sidebar__social-link sidebar__btn">
                    <svg className="sidebar__icon sidebar__icon-tw" xmlns="http://www.w3.org/2000/svg" width="16.969" height="13.81" viewBox="0 0 16.969 13.81">
                    <path id="Path_37" data-name="Path 37" d="M59.232,133.493a3.49,3.49,0,0,1-3.24-2.424,3.519,3.519,0,0,0,1.5-.048c.012,0,.024-.012.048-.024a3.5,3.5,0,0,1-2.268-1.6,3.449,3.449,0,0,1-.516-1.86,3.4,3.4,0,0,0,1.548.42,3.517,3.517,0,0,1-1.068-4.644,10.011,10.011,0,0,0,7.189,3.648c-.024-.132-.036-.264-.06-.384a3.458,3.458,0,0,1,.5-2.28,3.29,3.29,0,0,1,2.364-1.572,3.389,3.389,0,0,1,3.048.972.18.18,0,0,0,.18.06,6.834,6.834,0,0,0,2.028-.78c.012-.012.036-.012.048-.024h.024a3.55,3.55,0,0,1-1.488,1.9,6.5,6.5,0,0,0,1.944-.528l.012.012c-.132.18-.264.348-.4.528a6.706,6.706,0,0,1-1.272,1.2.126.126,0,0,0-.06.108,9.547,9.547,0,0,1-.06,1.548,10.334,10.334,0,0,1-2.784,5.856,9.446,9.446,0,0,1-4.788,2.676,9.563,9.563,0,0,1-1.944.228,9.848,9.848,0,0,1-5.58-1.5.689.689,0,0,1-.084-.048,7.066,7.066,0,0,0,3.492-.48A6.159,6.159,0,0,0,59.232,133.493Z" transform="translate(-54.06 -122.676)" />
                    </svg>
                </a>
                <a href="https://www.youtube.com/watch?v=Dt2qDLTBWbo&list=PLf7rTHPv8sncNG6VlXqmMJ7zZsDJYCvve" target= "blank" className="sidebar__social-link sidebar__btn">
                    <svg className="sidebar__icon sidebar__icon-yt" xmlns="http://www.w3.org/2000/svg" width="15.603" height="10.935" viewBox="0 0 15.603 10.935">
                    <path id="Path_55" data-name="Path 55" d="M194.688,134.341a1.817,1.817,0,0,1-1.2-1.164c-.516-1.428-.672-7.392.324-8.569a1.964,1.964,0,0,1,1.308-.7c2.688-.288,11-.252,11.965.1a1.862,1.862,0,0,1,1.176,1.128c.564,1.476.588,6.84-.072,8.257a1.808,1.808,0,0,1-.84.852C206.329,134.773,196.008,134.773,194.688,134.341Zm4.272-2.8c1.62-.84,3.216-1.668,4.848-2.508-1.632-.852-3.228-1.68-4.848-2.532Z" transform="translate(-193.081 -123.719)" />
                    </svg>
                </a>
            </div>

            

            </section>   
        </>
    )
}

export default Sidebar
