import * as api from '../api';

//Action Creators

export const getPlaylist = () => async (dispatch) => {
    try {
        const { data } = await api.fetchPlaylist();

        dispatch({ type: 'FETCH_PLAYLIST', payload: data });

    } catch( error ) {
        console.log(error.message);
    }
}

export const createPlaylist = (apply) => async (dispatch) => {
    try{
        const { data } = await api.createPlaylist(
            {
                spLink: apply.spLink,
                ytLink: apply.ytLink,
                bgImg: apply.bgImg,
            }
        );

        dispatch({ type: 'CREATE_PLAYLIST', payload: data });
        return(console.log("posted!!"))
    }catch (error) {
        console.log(error);
    }
}

export const updatePlaylist = (id, apply) => async(dispatch) => {
    try{
        const { data } = await api.updatePlaylist(id,{
            spLink: apply.spLink,
            ytLink: apply.ytLink,
            bgImg: apply.bgImg,

        });
        dispatch({ type: 'UPDATE_PLAYLIST', payload: data });
    }catch(error) {
        console.log(error);
    }
}

export const deletePlaylist = (id) => async(dispatch) => {
    try{
        await api.deletePlaylist(id);

        dispatch({ type: 'DELETE_PLAYLIST', payload: id})
    } catch(error) {
        console.log(error)
    }
}
