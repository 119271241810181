import * as api from '../api';

//Action Creators

export const getHeader = () => async (dispatch) => {
    try {
        const { data } = await api.fetchHeader();

        dispatch({ type: 'FETCH_HEADER', payload: data });

    } catch( error ) {
        console.log(error.message);
    }
}

export const createHeader = (apply) => async (dispatch) => {
    try{
        const { data } = await api.createHeader(
            {
                chLink: apply.chLink,
                apLink: apply.apLink,
                bgImg: apply.bgImg,
            }
        );

        dispatch({ type: 'CREATE_HEADER', payload: data });
        return(console.log("posted!!"))
    }catch (error) {
        console.log(error);
    }
}

export const updateHeader = (id, apply) => async(dispatch) => {
    try{
        const { data } = await api.updateHeader(id,{
            chLink: apply.chLink,
            apLink: apply.apLink,
            bgImg: apply.bgImg,

        });
        dispatch({ type: 'UPDATE_HEADER', payload: data });
    }catch(error) {
        console.log(error);
    }
}

export const deleteHeader = (id) => async(dispatch) => {
    try{
        await api.deleteHeader(id);

        dispatch({ type: 'DELETE_HEADER', payload: id})
    } catch(error) {
        console.log(error)
    }
}
