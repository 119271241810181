export default (header= [], action) => {
    switch (action.type) {
        case 'UPDATE_HEADER':
            return header.filter((header) => header.id !== action.payload);
        case 'UPDATE_HEADER':
            return header.map((header) => header.id === action.payload.id ? action.payload: header)
        case 'CREATE_HEADER':
            return [...header, action.payload];
        case 'FETCH_HEADER':
            return header = action.payload;
        default:
            return header
    }
}