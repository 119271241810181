import React from 'react';


import Sidebar from './../components/Sidebar';
import Heading from './../../components/Heading';

function AdminHome() {

    
        // const { isAuthenticated } = useAuth0();
    
        // let btn = null;
        // if (isAuthenticated) {
        //     btn = null
        // }else {
        //     btn = <LoginBtn/> 
            
        // }
        // document.querySelector(".example").style.backgroundColor = "red";
    return (
        <>
            <section className="adminHome">
                <div className="adminHome__container">
                    <div className="adminHome__navigation">
                        <Heading main= "Admin" sub= "Panel" addClass= "margin-bottom-small" />


                        <div className="adminBtnWrapper">
                        </div>
                        <Sidebar/> 
                    </div>
                    <div className="adminHome__content">
                        
                    </div>

                </div>
            </section>
        </>
    )
}

export default AdminHome
