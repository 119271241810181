import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getWork, deleteWork } from '../../actions/work';

import Sidebar from './../components/Sidebar';


import WorkForm from './../components/WorkForm';
import WorkCard from './../components/WorkCard';
import Heading from './../../components/Heading';

function PostWork() {

    const [currentId, setCurrentId] = useState(null);
    const [refreshState, setRefreshState] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWork());
    }, [refreshState, dispatch])

    const work = useSelector((state) => state.work);

    let dispWork = "";

    if(work.response){
        dispWork = work.response.map((dispWorkData) => (
        <WorkCard data= {dispWorkData} setCurrentId= {setCurrentId} deleteHandler= {(workId) => deleteFunc(workId)}  refreshState= {refreshState} setRefreshState= {setRefreshState} toggleContent = {(condition) => toggleContent(condition)}/>
    ))
    }

     //TOBEDISPLAYEDCONTENT
     const [addWork, setAddWork] = useState(false);

     let content = "";
     const toggleContent = (condition) => {
         setAddWork(condition);
     }
 
     if(addWork) {
         content = 
             <>
                 <button onClick = {() => toggleContent(false)} className= "btn__admin" >All Work</button>
                 <WorkForm currentId = {currentId} setCurrentId= {setCurrentId} refreshState= {refreshState} setRefreshState= {setRefreshState} toggleContent = {(condition) => toggleContent(condition)}/>
             </>
     }else {
         content =
             <>
                 <button onClick = {() => {toggleContent(true); setCurrentId(null)}} className= "btn__admin" >Add Work</button>
                 <section className="fetchedProducts">
                     {dispWork}
                 </section>
             </>
     }

     //DELETE NEWS
     const deleteFunc = (dispWorkId) => {
        dispatch(deleteWork(dispWorkId))
    }

    return (
        <>
             <section className="adminHome">
                <div className="adminHome__container">
                    <div className="adminHome__navigation">
                        <Heading main= "Admin" sub= "Panel" addClass= "margin-bottom-small" />


                        <Sidebar/>
                    </div>
                    <div className="adminHome__content">
                        {content}
                    </div>

                </div>
            </section>   
        </>
    )
}

export default PostWork
