import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getPlaylist, deletePlaylist } from '../../actions/playlist';

import Sidebar from './../components/Sidebar';


import PlaylistForm from './../components/PlaylistForm';
import PlaylistCard from './../components/PlaylistCard';
import Heading from './../../components/Heading';

function PostPlaylist() {

    const [currentId, setCurrentId] = useState(null);
    const [refreshState, setRefreshState] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPlaylist());
    }, [refreshState, dispatch])

    const playlist = useSelector((state) => state.playlist);

    let dispPlaylist = "";

    if(playlist.response){
        dispPlaylist = playlist.response.map((dispPlaylistData) => (
        <PlaylistCard data= {dispPlaylistData} setCurrentId= {setCurrentId} deleteHandler= {(playlistId) => deleteFunc(playlistId)}  refreshState= {refreshState} setRefreshState= {setRefreshState} toggleContent = {(condition) => toggleContent(condition)}/>
    ))
    }

     //TOBEDISPLAYEDCONTENT
     const [addPlaylist, setAddPlaylist] = useState(false);

     let content = "";
     const toggleContent = (condition) => {
         setAddPlaylist(condition);
     }
 
     if(addPlaylist) {
         content = 
             <>
                 <button onClick = {() => toggleContent(false)} className= "btn__admin" >All Playlist</button>
                 <PlaylistForm currentId = {currentId} setCurrentId= {setCurrentId} refreshState= {refreshState} setRefreshState= {setRefreshState} toggleContent = {(condition) => toggleContent(condition)}/>
             </>
     }else {
         content =
             <>
                 <button onClick = {() => {toggleContent(true); setCurrentId(null)}} className= "btn__admin" >Add Playlist</button>
                 <section className="fetchedProducts">
                     {dispPlaylist}
                 </section>
             </>
     }

     //DELETE NEWS
     const deleteFunc = (dispPlaylistId) => {
        dispatch(deletePlaylist(dispPlaylistId))
    }

    return (
        <>
             <section className="adminHome">
                <div className="adminHome__container">
                    <div className="adminHome__navigation">
                        <Heading main= "Admin" sub= "Panel" addClass= "margin-bottom-small" />


                        <Sidebar/>
                    </div>
                    <div className="adminHome__content">
                        {content}
                    </div>

                </div>
            </section>   
        </>
    )
}

export default PostPlaylist
