import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getHeader, deleteHeader } from '../../actions/header';

import Sidebar from './../components/Sidebar';


import HeaderForm from './../components/HeaderForm';
import HeaderCard from './../components/HeaderCard';
import Heading from './../../components/Heading';

function PostHeader() {

    const [currentId, setCurrentId] = useState(null);
    const [refreshState, setRefreshState] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHeader());
    }, [refreshState, dispatch])

    const header = useSelector((state) => state.header);

    let dispHeader = "";

    if(header.response){
        dispHeader = header.response.map((dispHeaderData) => (
        <HeaderCard data= {dispHeaderData} setCurrentId= {setCurrentId} deleteHandler= {(headerId) => deleteFunc(headerId)}  refreshState= {refreshState} setRefreshState= {setRefreshState} toggleContent = {(condition) => toggleContent(condition)}/>
    ))
    }

     //TOBEDISPLAYEDCONTENT
     const [addHeader, setAddHeader] = useState(false);

     let content = "";
     const toggleContent = (condition) => {
         setAddHeader(condition);
     }
 
     if(addHeader) {
         content = 
             <>
                 <button onClick = {() => toggleContent(false)} className= "btn__admin" >All Header</button>
                 <HeaderForm currentId = {currentId} setCurrentId= {setCurrentId} refreshState= {refreshState} setRefreshState= {setRefreshState} toggleContent = {(condition) => toggleContent(condition)}/>
             </>
     }else {
         content =
             <>
                 <button onClick = {() => {toggleContent(true); setCurrentId(null)}} className= "btn__admin" >Add Header</button>
                 <section className="fetchedProducts">
                     {dispHeader}
                 </section>
             </>
     }

     //DELETE NEWS
     const deleteFunc = (dispHeaderId) => {
        dispatch(deleteHeader(dispHeaderId))
    }

    return (
        <>
             <section className="adminHome">
                <div className="adminHome__container">
                    <div className="adminHome__navigation">
                        <Heading main= "Admin" sub= "Panel" addClass= "margin-bottom-small" />


                        <Sidebar/>
                    </div>
                    <div className="adminHome__content">
                        {content}
                    </div>

                </div>
            </section>   
        </>
    )
}

export default PostHeader
