import React from 'react'

function Heading(props) {
    return (
        <>
            <h1 className="admin-large--heading">
                        <span className="admin__heading-main">{props.main}</span>
                        <span className="admin__heading-sub">{props.sub}</span>
                    </h1>
        </>
    )
}

export default Heading
