export default (playlist= [], action) => {
    switch (action.type) {
        case 'UPDATE_PLAYLIST':
            return playlist.filter((playlist) => playlist.id !== action.payload);
        case 'UPDATE_PLAYLIST':
            return playlist.map((playlist) => playlist.id === action.payload.id ? action.payload: playlist)
        case 'CREATE_PLAYLIST':
            return [...playlist, action.payload];
        case 'FETCH_PLAYLIST':
            return playlist = action.payload;
        default:
            return playlist
    }
}