import * as api from '../api';

//Action Creators

export const getCategory = () => async (dispatch) => {
    try {
        const { data } = await api.fetchCategory();

        dispatch({ type: 'FETCH_CATEGORY', payload: data });

    } catch( error ) {
        console.log(error.message);
    }
}

export const createCategory = (apply) => async (dispatch) => {
    try{
        const { data } = await api.createCategory(
            {
                orgLink: apply.orgLink,
                covLink: apply.covLink,
                orgBgImg: apply.orgBgImg,
                covBgImg: apply.covBgImg,
            }
        );

        dispatch({ type: 'CREATE_CATEGORY', payload: data });
        return(console.log("posted!!"))
    }catch (error) {
        console.log(error);
    }
}

export const updateCategory = (id, apply) => async(dispatch) => {
    try{
        const { data } = await api.updateCategory(id,{
            orgLink: apply.orgLink,
            covLink: apply.covLink,
            orgBgImg: apply.orgBgImg,
            covBgImg: apply.covBgImg,

        });
        dispatch({ type: 'UPDATE_CATEGORY', payload: data });
    }catch(error) {
        console.log(error);
    }
}

export const deleteCategory = (id) => async(dispatch) => {
    try{
        await api.deleteCategory(id);

        dispatch({ type: 'DELETE_CATEGORY', payload: id})
    } catch(error) {
        console.log(error)
    }
}
