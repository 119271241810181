export default (apply= [], action) => {
    switch (action.type) {
        case 'UPDATE_APPLY':
            return apply.filter((apply) => apply.id !== action.payload);
        case 'UPDATE_APPLY':
            return apply.map((apply) => apply.id === action.payload.id ? action.payload: apply)
        case 'CREATE_APPLY':
            return [...apply, action.payload];
        case 'FETCH_APPLY':
            return apply = action.payload;
        default:
            return apply
    }
}