import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getApply, deleteApply } from '../../actions/apply';

import Sidebar from './../components/Sidebar';


import ApplyForm from './../components/ApplyForm';
import ApplyCard from './../components/ApplyCard';
import Heading from './../../components/Heading';

function PostApply() {

    const [currentId, setCurrentId] = useState(null);
    const [refreshState, setRefreshState] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getApply());
    }, [refreshState, dispatch])

    const apply = useSelector((state) => state.apply);

    let dispApply = "";

    if(apply.response){
        dispApply = apply.response.map((dispApplyData) => (
        <ApplyCard data= {dispApplyData} setCurrentId= {setCurrentId} deleteHandler= {(applyId) => deleteFunc(applyId)}  refreshState= {refreshState} setRefreshState= {setRefreshState} toggleContent = {(condition) => toggleContent(condition)}/>
    ))
    }

     //TOBEDISPLAYEDCONTENT
     const [addApply, setAddApply] = useState(false);

     let content = "";
     const toggleContent = (condition) => {
         setAddApply(condition);
     }
 
     if(addApply) {
         content = 
             <>
                 <button onClick = {() => toggleContent(false)} className= "btn__admin" >All Apply</button>
                 <ApplyForm currentId = {currentId} setCurrentId= {setCurrentId} refreshState= {refreshState} setRefreshState= {setRefreshState} toggleContent = {(condition) => toggleContent(condition)}/>
             </>
     }else {
         content =
             <>
                 <button onClick = {() => {toggleContent(true); setCurrentId(null)}} className= "btn__admin" >Add Apply</button>
                 <section className="fetchedProducts">
                     {dispApply}
                 </section>
             </>
     }

     //DELETE NEWS
     const deleteFunc = (dispApplyId) => {
        dispatch(deleteApply(dispApplyId))
    }

    return (
        <>
             <section className="adminHome">
                <div className="adminHome__container">
                    <div className="adminHome__navigation">
                    <Heading main= "Admin" sub= "Panel" addClass= "margin-bottom-small" />


                        <Sidebar/>
                    </div>
                    <div className="adminHome__content">
                        {content}
                    </div>

                </div>
            </section>   
        </>
    )
}

export default PostApply
