import * as api from '../api';

//Action Creators

export const getWork = () => async (dispatch) => {
    try {
        const { data } = await api.fetchWork();

        dispatch({ type: 'FETCH_WORK', payload: data });

    } catch( error ) {
        console.log(error.message);
    }
}

export const createWork = (apply) => async (dispatch) => {
    try{
        const { data } = await api.createWork(
            {
                stLink: apply.stLink,
                bgImg: apply.bgImg,
            }
        );

        dispatch({ type: 'CREATE_WORK', payload: data });
        return(console.log("posted!!"))
    }catch (error) {
        console.log(error);
    }
}

export const updateWork = (id, apply) => async(dispatch) => {
    try{
        const { data } = await api.updateWork(id,{
            stLink: apply.stLink,
            bgImg: apply.bgImg,

        });
        dispatch({ type: 'UPDATE_WORK', payload: data });
    }catch(error) {
        console.log(error);
    }
}

export const deleteWork = (id) => async(dispatch) => {
    try{
        await api.deleteWork(id);

        dispatch({ type: 'DELETE_WORK', payload: id})
    } catch(error) {
        console.log(error)
    }
}
