export default (brand= [], action) => {
    switch (action.type) {
        case 'UPDATE_BRAND':
            return brand.filter((brand) => brand.id !== action.payload);
        case 'UPDATE_BRAND':
            return brand.map((brand) => brand.id === action.payload.id ? action.payload: brand)
        case 'CREATE_BRAND':
            return [...brand, action.payload];
        case 'FETCH_BRAND':
            return brand = action.payload;
        default:
            return brand
    }
}