import React, {useEffect} from 'react'

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { getPlaylist } from './../actions/playlist';
import { imgDirectory } from './ImgDirectory';

function Playlists() {
    
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getPlaylist());

    },[])


    const playlist= useSelector((state) => state.playlist.response);

    let spLink,ytLink,bgImg;
    if(Array.isArray(playlist)){
        playlist.map((data,index) => {
            spLink = data.spLink;
            ytLink = data.ytLink;
            bgImg = data.bgImg;
        })
    }

    return (
        <>
            <section className="playlists">
                <img src={imgDirectory + bgImg} alt="" className="playlists__bgImg" />
                <div className="playlists__container">
                    <h1 className="heading-large--white playlists__heading">
                        <span className="header__heading-main">Our</span>
                        <span className="header__heading-sub">Playlists</span>
                    </h1>
                    <div className="header__btnGroup">
                        <a href={spLink} className="cta">Spotify</a>
                        <a href={ytLink} target="blank" className="cta">Youtube</a>
                    </div>
                    <p className="header__mark heading-tiny">MIYANI SESSIONS</p>
                </div>
            </section>   
        </>
    )
}

export default Playlists
