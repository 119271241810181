import { combineReducers } from 'redux';

import apply from './apply';
import brand from './brand';
import category from './category';
import header from './header';
import merch from './merch';
import playlist from './playlist';
import work from './work';


export default combineReducers({ apply, brand, category, header, merch, playlist, work })
