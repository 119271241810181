export default (category= [], action) => {
    switch (action.type) {
        case 'UPDATE_CATEGORY':
            return category.filter((category) => category.id !== action.payload);
        case 'UPDATE_CATEGORY':
            return category.map((category) => category.id === action.payload.id ? action.payload: category)
        case 'CREATE_CATEGORY':
            return [...category, action.payload];
        case 'FETCH_CATEGORY':
            return category = action.payload;
        default:
            return category
    }
}