import React from 'react'

function Navigation() {
    return (
        <>
            <section className="navigation">
                <div className="navigation__container">
                    <div className="navigation__wrapper">
                        <nav className="navigation__links">
                            <a href="#" className="navigation__link">Team</a>
                            <a href="#" className="navigation__link">About</a>
                            <a href="#" className="navigation__link">Studios</a>
                            <a href="#" className="navigation__link">Mastering</a>
                            <a href="#" className="navigation__link">Contact</a>
                        </nav>
                        <div className="navigation__btnGroup">
                            <div className="flexSpacer"></div>
                            <div className="flexSpacer"></div>
                            <div className="flexSpacer"></div>
                            <div className="flexSpacer"></div>
                            <a className="cta">Get Quote</a>
                        </div>
                        
                    </div>
                    {/* <p className="header__mark heading-tiny">MIYANI SESSIONS</p> */}

                </div>
            </section>
        </>
    )
}

export default Navigation
