import React from 'react'



import brandImg1 from '../assets/img/logos/brand1.png';
import brandImg2 from '../assets/img/logos/brand2.png';
import brandImg3 from '../assets/img/logos/brand3.png';
import brandImg4 from '../assets/img/logos/brand4.png';

import { Swiper } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import { SwiperSlide } from 'swiper/react';

function Brand() {
    SwiperCore.use([Autoplay]);
    
    let numSlides = 4;
    let slideDirection = "vertical"
    function myFunction(x) {
        if (x.matches) { // If media query matches
            numSlides = 3;
            slideDirection = "horizontal"

        } else {
            numSlides = 4;
            slideDirection = "vertical"
        }
      }
      
      var x = window.matchMedia("(max-width: 580px)")
      myFunction(x) // Call listener function at run time
      x.addListener(myFunction) // Attach listener function on state changes

    return (
        <>
            <section className="brand">
                <div className="brand__container">
                    <div className="brand__wrapper">
                        <h1 className="heading-large--black">
                            <span className="header__heading-main">Brand</span>
                            <span className="header__heading-sub">Partners</span>
                        </h1>
                        <div className="header__btnGroup brand__btnGroup-resp">
                            <a className="cta cta-bl">Apply Now</a>
                        </div>
                        <p className="header__mark heading-tiny">MIYANI SESSIONS</p>
                    </div>
                    <div className="brand__slider">
                        <Swiper
                            slidesPerView = {numSlides}
                            spaceBetween= {66}
                            autoplay= {
                                {delay: 2500,
                                disableOnInteraction: false,
                            }
                            }
                            loop= {true}
                            // speed= {5000}
                            grabCursor = {true}
                            direction={slideDirection}
                            autoHeight={true}
                      
                            >
                            <SwiperSlide>
                                <div className="brand__slider-imgBox">
                                    <img src={brandImg1} alt="" className="brand__slider-img"/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="brand__slider-imgBox">
                                    <img src={brandImg2} alt="" className="brand__slider-img"/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="brand__slider-imgBox">
                                    <img src={brandImg3} alt="" className="brand__slider-img"/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="brand__slider-imgBox">
                                    <img src={brandImg4} alt="" className="brand__slider-img"/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="brand__slider-imgBox">
                                    <img src={brandImg2} alt="" className="brand__slider-img"/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="brand__slider-imgBox">
                                    <img src={brandImg3} alt="" className="brand__slider-img"/>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <p className="header__mark heading-tiny--bl">MIYANI SESSIONS</p>
                    {/* <div className="hr-1"></div>
                    <div className="hr-2"></div>
                    <div className="hr-3"></div>
                    <div className="hr-4"></div>
                    <div className="hr-5"></div> */}
                    <div className="header__btnGroup brand__btnGroup">
                        <a className="cta cta-bl">Apply Now</a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Brand
