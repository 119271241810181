import React from 'react'
import { Link } from 'react-router-dom';
// import { useAuth0 } from "@auth0/auth0-react";
// import LoginBtn from './LoginBtn';
// import LogoutBtn from './LogoutBtn';
function Sidebar() {
    // const { isAuthenticated } = useAuth0();
    // console.log(isAuthenticated)
    return (
        <>
               <div className="adminHome__navigation-wrapper">
                            <Link exact to="/admin/add-header" className= "adminHome__navigation-item" >
                                Header Section
                            </Link>
                            <Link exact to="/admin/add-category" className= "adminHome__navigation-item" >
                                Category Section
                            </Link>
                            <Link exact to="/admin/add-apply" className= "adminHome__navigation-item" >
                                Apply Section
                            </Link>
                            <Link exact to="/admin/add-work" className= "adminHome__navigation-item" >
                                Work Section
                            </Link>
                            <Link exact to="/admin/add-merch" className= "adminHome__navigation-item" >
                                Merch Section
                            </Link>
                            <Link exact to="/admin/add-playlist" className= "adminHome__navigation-item" >
                                Playlist Section
                            </Link>
                           
                            {/* <div className="adminBtnWrapper">
                            {isAuthenticated ? <LogoutBtn/> : <LoginBtn/>}  
                        </div> */}
                            
                </div>
        </>
    )
}

export default Sidebar
