import * as api from '../api';

//Action Creators

export const getBrand = () => async (dispatch) => {
    try {
        const { data } = await api.fetchBrand();

        dispatch({ type: 'FETCH_BRAND', payload: data });

    } catch( error ) {
        console.log(error.message);
    }
}

export const createBrand = (apply) => async (dispatch) => {
    try{
        const { data } = await api.createBrand(
            {
                apLink: apply.apLink,
                logos: apply.logos,
            }
        );

        dispatch({ type: 'CREATE_BRAND', payload: data });
        return(console.log("posted!!"))
    }catch (error) {
        console.log(error);
    }
}

export const updateBrand = (id, apply) => async(dispatch) => {
    try{
        const { data } = await api.updateBrand(id,{
            apLink: apply.apLink,
            logos: apply.logos,

        });
        dispatch({ type: 'UPDATE_BRAND', payload: data });
    }catch(error) {
        console.log(error);
    }
}

export const deleteBrand = (id) => async(dispatch) => {
    try{
        await api.deleteBrand(id);

        dispatch({ type: 'DELETE_BRAND', payload: id})
    } catch(error) {
        console.log(error)
    }
}
