import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// import cateImg1 from '../assets/img/cateImg1.png';
// import cateImg2 from '../assets/img/cateImg2.png';
import { getCategory } from './../actions/category';
import { imgDirectory } from './ImgDirectory';


function Category() {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getCategory());

    },[])


    const category= useSelector((state) => state.category.response);

    let orgLink,covLink,orgBgImg,covBgImg;
    if(Array.isArray(category)){
        category.map((data,index) => {
            orgLink = data.orgLink;
            covLink = data.covLink;
            orgBgImg = data.orgBgImg;
            covBgImg = data.covBgImg;
        })
    }
    // console.log(categoryData)
    // let displayClients ;

    // if(Array.isArray(clients)){
    //     displayClients = clients.map((clientData, index) => (
    //         <SwiperSlide>
    //             <img src={imgDirectory + clientData.cLogo} alt="partner-logo" className="home__about-logo" />
    //         </SwiperSlide>
    //     ))
    // }

    return (
        <>
            <section className="category">
                <div className="category__container">
                    <div className="category__wrapper">
                        <a href={orgLink} target="blank" className="category__content">
                            <img src={imgDirectory + orgBgImg} alt="" className="category__img"/>
                            <div className="category__overlay"></div>
                            <p className="heading-small category__title">Originals</p>
                        </a>
                        <a href={covLink} target="blank" className="category__content">
                            <img src={imgDirectory + covBgImg} alt="" className="category__img"/>
                            <div className="category__overlay"></div>
                            <p className="heading-small category__title">Covers</p>
                        </a>
                    </div>
                    <p className="category__mark heading-tiny">MIYANI SESSIONS</p>
                </div>
            </section>
        </>
    )
}

export default Category
