import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Heading from './../../components/Heading';

import FileBase64 from 'react-file-base64';
import { createApply, updateApply } from '../../actions/apply';

import loadingImg from '../../assets/img/loading.gif';


function ApplyForm({ currentId, setCurrentId, refreshState, setRefreshState, toggleContent }) {
    const dispatch = useDispatch();

    const [ applyData, setApplyData ] = useState({
        subLink: '',
        bgImg: '',
      
    })

    const [ loading, setLoading ] = useState(null)

    const apply = useSelector((state) => currentId ? state.apply.response.find((p) => p.id === currentId) : null);

    useEffect(() => {
        if(apply){ setApplyData(apply);}
    }, [apply])

    const handleSubmit = (e) => {
        e.preventDefault(); //not to get refresh in the browser

        setLoading(<div  className="loadingStatus" ><img src={loadingImg} alt=""/> <h5> Uploading!</h5></div>)

        if(currentId){
                    dispatch(updateApply(currentId, applyData)).then(() => toggleContent(false));
                    setRefreshState(!refreshState);
    
    
                }else{
                    dispatch(createApply(applyData)).then(() => toggleContent(false));
                    setRefreshState(!refreshState);
    
                }
                clear();
    }

    const clear = () => {
        setCurrentId(null);

        setApplyData({
            subLink: '',
            bgImg: '',
        })
    }

    return (
        <>
            <section className= "postProduct">
                <div className= "postProduct__wrapper">
                    <Heading main= "Admin" regular= "Panel" addClass= "margin-bottom-small" />

                    <form autoComplete= "off" className= "contact__modal-form" onSubmit= {handleSubmit}>
                        <input type="text" id="subLink" title="subLink" placeholder="Submit Your Music Link" value= {applyData.subLink} onChange= {(e) => setApplyData({...applyData, subLink: e.target.value })} required/>
                       
                        <div className= "margin-bottom-small">
                            Background Image: <FileBase64 type="file" multiple={false} onDone={({base64}) => {setApplyData({ ...applyData, bgImg: base64 }) }} />
                        </div>
        
                        <div className="btn__adminWrapper">
                            <button className="btn__admin margin-bottom-small" type= "submit">Submit</button>
                            {loading}
                        </div>
                    </form>
                </div>
            </section>  
        </>
    )
}

export default ApplyForm
